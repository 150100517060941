import { useNavigate, useSearchParams } from "react-router-dom";

export default function ThankYou() {

	const [searchParams] = useSearchParams();

	const navigate = useNavigate();

	return (
		<div className="modal modal-open">
			<div className="modal-box bg-white rounded-lg w-[95%] md:w-[80%] lg:w-[50%] max-w-5xl relative min-h-fit">
				<div className="flex flex-col items-center justify-center w-full gap-4">
					<h1 className="text-4xl font-semibold my-4 font-inter">Thank You!</h1>
					<div className="flex flex-col *:font-inter items-center">
						<p>We have recieved your payment and the status will be updated to you shortly. </p>
						<p>Thank you for choosing ChiroScript AI.</p>
					</div>
					<button onClick={() => navigate('/')} className="btn flex flex-row mt-[0.8rem] mb-4 lg:mb-0 mx-auto bg-accent stroke-none outline-none border-none w-[50%] hover:bg-accent/95 focus:bg-opacity-100 text-white hover:text-font-800 text-[1.2rem] font-inter font-bold transition-all duration-500 ease-in-out group justify-center focus:border-primary focus:border-[1px] focus:border-solid disabled:bg-gray-300 disabled:text-gray-400 disabled:hover:bg-gray-300 disabled:hover:text-gray-400 disabled:cursor-not-allowed">Continue to Dashboard!</button>
				</div>
			</div>
		</div>
	);
}
